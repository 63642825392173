import { Link } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { StaticImage } from 'gatsby-plugin-image';
import { SpecialPanel } from '../components/Special-Panel';
import { colors, device } from '../components/layout/GlobalStyles';
import styled from '@emotion/styled';
import { VipPanel } from '../components/Vip-Panel';
import { Vip } from '../components/layout/Vip';
import { Seo } from '../components/Seo';

const StyledVipList = styled.div``;

const VipList: Page<Props> = () => {
    return (
        <>
            <DefaultLayout vip>
                <Seo
                    title="VIP List, FL | Smilecraft Dental Studio"
                    description="New VIP List Options. Are you new to the area? Visit Smilecraft Dental Studio to receive your initial dental exam, cleaning, and X-rays."
                />
                <RegularHero prevPage="home" currentPage="VIP Membership">
                    <StaticImage
                        quality={100}
                        className="regular-hero-image"
                        src="../images/vip-hero.jpg"
                        placeholder="blurred"
                        alt="dentist demonstrating with patient"
                    />
                </RegularHero>
                <StyledVipList>
                    <Vip vip />
                    <VipPanel vip />
                </StyledVipList>
            </DefaultLayout>
        </>
    );
};

export default VipList;
