import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { SlantedPicture } from './SlantedPicture';

const StyledVipPanel = styled.div`
    padding: 2em 1em;
    background: ${colors.blue};
    display: flex;
    flex-direction: column;
    align-items: center;
    h3,
    p {
        color: ${colors.white};
        text-align: center;
    }
    h4 {
        text-align: center;
    }
    h3 {
        width: 70%;
        line-height: 74.8px;
        margin-bottom: 0.5em;
    }

    h4 {
        line-height: 44.8px;
        margin-top: 0.5em;
        font-size: 32px;
    }

    .box {
        h4 {
            padding-top: 0.25em;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #efefef;
        margin: 2em 0em;
        max-width: 415px;
        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.2);
        border-radius: 16px;
        padding: 2em;
    }
    .box-container {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        h3 {
            margin-top: 0.5em;
            font-size: 52px;
        }
        p {
            width: 80%;
        }
        .box {
            margin: 1em 0em;
            min-width: 404px;
        }
        .box-container {
            flex-wrap: wrap;
            justify-content: space-between;
            display: flex;
            width: 860px;
        }
    }
    @media ${device.laptopL} {
        h3 {
            margin-bottom: 1em;
            font-size: 62px;
        }
    }
`;

interface Props{
    vip?:boolean
}

export const VipPanel: React.FC<Props> = ({vip}) => {
    return (
        <StyledVipPanel>
            {!vip&&<h3 className="header">Join our SmileCraft VIP list today!</h3>}
            <p style={{ fontSize: '24px', fontWeight: '900' }}>Benefits Include:</p>
            <div className="box-container">
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>Priority Booking</h4>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>Free SmileCraft Gear</h4>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4> Special offers</h4>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check"
                        placeholder="none"
                    />
                    <h4>Discounts</h4>
                </div>
            </div>
        </StyledVipPanel>
    );
};
